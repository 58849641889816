import {
    Badge,
    Box,
    Button,
    Drawer,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import CartListItem from "./CartListItem";
import {Link, useSearchParams} from "@remix-run/react";
import {useMainData, useProducts, useSettingsData} from "~/hooks";
import {ServerOnly} from "remix-utils/server-only";
import {ClientOnly} from "remix-utils/client-only";
import {useEffect, useMemo, useRef} from "react";
import {useCartStore} from "~/store";
import {ShoppingBasket} from "@mui/icons-material";
import {usePopupState} from "material-ui-popup-state/hooks";
import {useTranslation} from "react-i18next";

interface CartProps {
    listItem?: boolean;
}

export default function Cart({listItem}: CartProps) {
    const {t} = useTranslation();
    const [cart, total, recalculateCart] = useCartStore((s) => [
        s.cart,
        s.total,
        s.recalculateCart,
    ]);
    const {shippingMethods} = useMainData();
    const settings = useSettingsData();
    const products = useProducts();

    const drawerRef = useRef(null);
    const cartBtnRef = useRef<HTMLButtonElement>(null);
    const {isOpen, setOpen} = usePopupState({
        variant: "popover",
        popupId: "cart-popup",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const cartOpen = searchParams.get("cart") === "1";
    if (!cartOpen && isOpen) {
        // Setting a URL param for closing the cart makes the cart close when clicking on "Checkout" even if we already are on the checkout page.
        // We are not doing this for opening the cart as well so the SSR page won't load with the cart openend (and thus not matching the local cart state).
        setOpen(false);
    }

    const freeShippingThreshold = useMemo(() => {
        let minThereshold: number | null = null;
        shippingMethods.forEach((s) => {
            if (s.freeTreshold) {
                minThereshold = s.freeTreshold;
            }
        });
        return minThereshold;
    }, [shippingMethods]);
    const freeShippingRemainingPrice = useMemo(
        () =>
            freeShippingThreshold ? Math.max(freeShippingThreshold - total, 0) : null,
        [freeShippingThreshold, total]
    );

    const variationCount = useMemo(() => {
        let vCount = 0;
        for (const pId of Object.values(cart)) {
            vCount += Object.keys(pId).length;
        }
        return vCount;
    }, [cart]);

    useEffect(() => {
        // Update cart prices and removals
        recalculateCart(products);
    }, [recalculateCart, products]);

    const openCart = () => {
        const params = searchParams;
        params.set("cart", "1");
        setSearchParams(params);
        setOpen(true, drawerRef.current);
        cartBtnRef.current?.scrollIntoView();
    };

    const dismissCart = () => {
        const params = searchParams;
        params.delete("cart");
        setSearchParams(params);
    };

    const hasProducts = Object.keys(cart).length > 0;
    const hasFreeShipping = freeShippingRemainingPrice === 0;

    const drawerWidth = {
        xs: "100%",
        md: "400px",
    };
    return (
        <>
            <ServerOnly>
                {() => (
                    <IconButton>
                        <ShoppingBasket/>
                    </IconButton>
                )}
            </ServerOnly>
            <ClientOnly>
                {() =>
                    listItem ? (
                        <ListItem onClick={openCart}>
                            <ListItemIcon>
                                <Badge
                                    badgeContent={variationCount}
                                    color={hasProducts ? "secondary" : undefined}
                                >
                                    <ShoppingBasket/>
                                </Badge>
                            </ListItemIcon>
                            <ListItemText>{t("cart")}</ListItemText>
                        </ListItem>
                    ) : (
                        <Badge
                            badgeContent={variationCount}
                            color={hasProducts ? "secondary" : undefined}
                        >
                            <IconButton onClick={openCart}>
                                <ShoppingBasket/>
                            </IconButton>
                        </Badge>
                    )
                }
            </ClientOnly>

            <Drawer
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
                ref={drawerRef}
                anchor="right"
                open={isOpen}
                onClose={dismissCart}
            >
                <Box
                    sx={{
                        width: drawerWidth,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingTop: 4,
                        paddingBottom: 4,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <div style={{flexGrow: 1}}></div>
                    {hasProducts &&
                        Object.keys(cart).map((cartProdId) =>
                            Object.keys(cart[cartProdId]).map((vId) => (
                                <CartListItem
                                    key={vId}
                                    productId={cartProdId}
                                    variationId={vId}
                                />
                            ))
                        )}
                    {!hasProducts && (
                        <Typography sx={{textAlign: "center", marginBottom: 2}}>
                            {t("cartCta")}
                        </Typography>
                    )}
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {hasProducts && (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: (t) => t.spacing(2),
                                    }}
                                >
                                    <Typography>{t("subtotal")}</Typography>
                                    <Typography>
                                        {total} {settings.defaultCurrency}
                                    </Typography>
                                </Box>
                                {!!freeShippingThreshold && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginBottom: (t) => t.spacing(2),
                                        }}
                                    >
                                        <Typography sx={{
                                            color: (t) =>
                                                hasFreeShipping
                                                    ? t.palette.success.main
                                                    : undefined,
                                        }}
                                                    variant="subtitle2">{t(hasFreeShipping ? "freeShippingReached" : "freeShippingThreshold")}</Typography>
                                        {!hasFreeShipping && <Typography variant="subtitle2">
                                            {freeShippingRemainingPrice} {settings.defaultCurrency}
                                        </Typography>}
                                    </Box>
                                )}
                                <Link to="/checkout">
                                    <Button style={{width: "100%"}} variant="contained">
                                        {t("checkout")}
                                    </Button>
                                </Link>
                            </>
                        )}

                        <Button
                            ref={cartBtnRef}
                            onClick={dismissCart}
                            variant={hasProducts ? undefined : "contained"}
                        >
                            {t("continueShopping")}
                        </Button>
                    </div>
                </Box>
            </Drawer>
        </>
    );
}

import {
    Box,
    Grid,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import DtwImage from "./DtwImage";
import {useMainData, useSettingsData, useTextColorForPrimaryBg} from "~/hooks";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import i18nconfig from "~/i18n";
import {useLocation} from "@remix-run/react";
import ReactCountryFlag from "react-country-flag";
import { ClientOnly } from "remix-utils/client-only";

export default function Footer() {
    const main = useMainData();
    const settings = useSettingsData();
    const primaryBgTextColor = useTextColorForPrimaryBg();
    const {t, i18n} = useTranslation();
    const loc = useLocation();

    const currentLng = i18n.language;
    const languages = useMemo(() => {
        const supportedLangs = i18nconfig.supportedLngs;
        const languageCodes = supportedLangs.filter(l => settings.enabledLocales.includes(l));
        return languageCodes.map((languageCode) => {
            const nameGenerator = new Intl.DisplayNames(languageCode, {
                type: "language",
            });
            const displayName = nameGenerator.of(languageCode);
            return {value: languageCode, label: displayName};
        });
    }, []);

    const setLanguage = (e: SelectChangeEvent<string>) => {
        const pathParts = loc.pathname.split("/");
        if (
            pathParts.length < 2 ||
            !i18nconfig.supportedLngs.includes(pathParts[1])
        ) {
            // The URL does not already include the language
            window.location.pathname = `/${e.target.value}${window.location.pathname}`;
            return;
        }

        // The URL already includes the language, replace it
        pathParts[1] = e.target.value;
        window.location.pathname = pathParts.join("/");
    };

    const {policies, socialLinks, photoLinks, logo, shortDescription, slogan} =
        main;

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={(t) => ({
                    alignItems: "center",
                    padding: t.spacing(2),
                    backgroundColor: t.palette.primary.main,
                })}
            >
                <Grid item xs={12} md={2} display="flex" justifyContent="center">
                    <DtwImage picture={logo} alt={t("storeLogo")} sx={{maxWidth: 96}}/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    display="flex"
                    justifyContent="center"
                    gap={1}
                    flexDirection="column"
                >
                    <Typography color={primaryBgTextColor} variant="body2">
                        {slogan}
                    </Typography>
                    <Typography color={primaryBgTextColor} variant="body2">
                        {shortDescription}
                    </Typography>
                    {socialLinks.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: (t) => t.spacing(2),
                            }}
                        >
                            {socialLinks.map((sLink) => (
                                <Box
                                    sx={{
                                        appearance: "none",
                                        outline: "none",
                                        border: 0,
                                        background: "transparent",
                                    }}
                                    component="button"
                                    key={`smedia-${sLink.type}`}
                                >
                                    <a href={sLink.href} target="_blank" rel="noreferrer">
                                        <img
                                            src={sLink.icon}
                                            alt={sLink.type}
                                            style={{
                                                height: 25,
                                                width: 25,
                                            }}
                                        />
                                    </a>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={2}
                    display="flex"
                    justifyContent="center"
                    style={{
                        flexDirection: "column",
                        flexWrap: "wrap",
                    }}
                >
                    {policies.map((link, index) => (
                        <Link
                            sx={{textDecoration: "underline"}}
                            color={primaryBgTextColor}
                            key={`footerlink-${index}`}
                            href={`/policies/${link.href}`}
                        >
                            {link.text}
                        </Link>
                    ))}
                </Grid>
                {languages.length > 1 && <Grid item xs={12} md={1}>
                    <ClientOnly>
                        {() => <div>
                            <Select
                                sx={{color: primaryBgTextColor}}
                                value={currentLng}
                                onChange={setLanguage}
                                label={t("language")}
                                fullWidth={false}
                                variant={"outlined"}
                                renderValue={(cultureCode) => {
                                    const countryCode = cultureCode.split('-')[1] ?? settings.storeLocale.split('-')[1];
                                    return (
                                        <Box sx={{display: "flex", gap: 1}}>
                                            <ReactCountryFlag countryCode={countryCode} svg/>
                                        </Box>
                                    );
                                }}
                            >
                                {languages.map((cultureCode) => {
                                    const countryCode = cultureCode.value.split('-')[1] ?? settings.storeLocale.split('-')[1];
                                    return (<MenuItem key={cultureCode.value} value={cultureCode.value}>
                                            <ReactCountryFlag countryCode={countryCode} svg/>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </div>}
                    </ClientOnly>
                </Grid>}
            </Grid>

            <Typography variant="body2" sx={{m: (t) => t.spacing()}}>
                Powered by{" "}
                <a
                    style={{textDecoration: "underline"}}
                    href="https://dropthework.com"
                >
                    DropTheWork
                </a>
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    padding: (t) => t.spacing(1),
                    gap: (t) => t.spacing(2),
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
            >
                {photoLinks.map((pLink, index) => (
                    <a
                        href={pLink.href}
                        target="_blank"
                        rel="noreferrer"
                        key={`footerphotolink-${index}`}
                    >
                        <DtwImage
                            picture={pLink.picture}
                            alt={pLink.alt}
                            sx={{maxWidth: 150}}
                        />
                    </a>
                ))}
            </Box>
        </>
    );
}
